import React, { useEffect, useState } from 'react'
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { VerificationType } from '@genoa/domain'
import { GENERIC_ERROR_MODAL_CONTENT as errorContent } from '@genoa/screen-content'
import { useQueryError } from '@genoa/state-management'
import { getDateTime } from '@genoa/utils'

import { useAuthState } from '../../../../../contexts'
import { useShowErrorMessageModal } from '../../../../../hooks'
import { useOfferState } from '../../../../../modules/flex2/use-state'
import { useStartVerificationMutation } from '../../../../../modules/flexApi'
import { loggerV2, useAnalytics } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import { UploadPaystubs } from './UploadPaystubs'

export const UploadPaystubsContainer = () => {
  const analytics = useAnalytics()
  const { uid } = useAuthState()
  const navigate = useNavigate()
  const offerState = useOfferState()
  const today = getDateTime()
  const { showErrorMessage } = useShowErrorMessageModal()

  const [linkToken, setLinkToken] = useState('')
  const [plaidIsOpen, setPlaidIsOpen] = useState(false)

  const [startVerification, { isLoading, error: startVerificationError }] = useStartVerificationMutation()

  const pastThirtyDaysDate = today.minus({ month: 1 }).toFormat('MM/dd/yyyy')
  const getNextBpMonth = () => {
    const tMinus2 = today.set({ day: today.daysInMonth - 2 })
    const tPlus3 = today.plus({ month: 1 }).set({ day: 3 })
    if (tMinus2.toMillis() <= today.toMillis() && today.toMillis() < tPlus3.toMillis()) {
      return tPlus3.monthLong
    }
    return undefined
  }

  const openPlaid = (plaidHostedLinkUrl: string) => {
    analytics.logEvent(Analytics.Events.INCOME_VERIFICATION_UPLOAD_PAYSTUBS_CLICKED)
    window.open(plaidHostedLinkUrl, '_parent')
  }

  const onClickContinue = () => {
    analytics.logEvent(Analytics.Events.INCOME_VERIFICATION_UPLOAD_PAYSTUBS_CTA_CLICKED)

    startVerificationForUploadPaystubs()
  }

  const startVerificationForUploadPaystubs = async () => {
    const response = await startVerification({
      customerId: uid!,
      verificationId: offerState.offer.onboarding_income_verification_id!,
      verification_type: VerificationType.DOCUMENT,
    }).unwrap()

    if (response.data?.verification_url) {
      analytics.logEvent(Analytics.Events.IV_START_VERIFICATION_SUCCESS)

      openPlaid(response.data?.verification_url)
    }
  }

  useQueryError(startVerificationError, {
    onAllErrors() {
      analytics.logEvent(Analytics.Events.IV_START_VERIFICATION_FAILURE)

      showErrorMessage(errorContent.TITLE, errorContent.SUBTITLE)
    },
  })

  useEffect(() => {
    if (linkToken === '') {
      startVerificationForUploadPaystubs()
    }
  }, [linkToken])

  const onClickConnectYourBankAccount = () => {
    analytics.logEvent(Analytics.Events.INCOME_VERIFICATION_CONNECT_YOUR_BANK_CLICKED)

    navigate(Routes.Onboarding.VERIFY_YOUR_INCOME)
  }

  return (
    <UploadPaystubs
      pastThirtyDaysDate={pastThirtyDaysDate}
      nextBpMonth={getNextBpMonth()}
      onClickContinue={onClickContinue}
      onClickConnectYourBankAccount={onClickConnectYourBankAccount}
    />
  )
}
