import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { VerificationType } from '@genoa/domain'
import {
  GENERIC_ERROR_MODAL_CONTENT as errorContent,
  INCOME_VERIFICATION_AMOUNT_LOCAL_STORAGE,
} from '@genoa/screen-content'
import { useQueryError } from '@genoa/state-management'
import { Flex } from '@chakra-ui/react'

import { useAuthState, useModal } from '../../../../../contexts'
import { useShowErrorMessageModal } from '../../../../../hooks'
import { useOfferState } from '../../../../../modules/flex2/use-state'
import { useStartVerificationMutation } from '../../../../../modules/flexApi'
import { useAnalytics } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import { VerifyYourIncome } from './VerifyYourIncome'

export const VerifyYourIncomeContainer = () => {
  const analytics = useAnalytics()
  const { uid } = useAuthState()
  const offerState = useOfferState()
  const { showErrorMessage } = useShowErrorMessageModal()
  const modal = useModal()
  const navigate = useNavigate()
  const [loadingPlaid, setLoadingPlaid] = useState(false)
  const [url, setUrl] = useState<string | undefined>(undefined)

  const [startVerification, { isLoading, error: startVerificationError }] = useStartVerificationMutation()
  const incomeAmount = localStorage.getItem(INCOME_VERIFICATION_AMOUNT_LOCAL_STORAGE)!

  const openPlaid = (plaidHostedLinkUrl: string) => {
    analytics.logEvent(Analytics.Events.INCOME_VERIFICATION_CONNECT_YOUR_BANK_CLICKED)
    window.open(plaidHostedLinkUrl, '_parent')
  }

  const handleOnClickContinue = () => {
    analytics.logEvent(Analytics.Events.INCOME_VERIFICATION_BANK_LINK_CTA_CLICKED)
    handleStartVerification()
  }

  const handleStartVerification = async () => {
    const response = await startVerification({
      customerId: uid!,
      verificationId: offerState.offer.onboarding_income_verification_id!,
      verification_type: VerificationType.BANK_ACCOUNT,
    }).unwrap()

    if (response.data?.verification_url) {
      analytics.logEvent(Analytics.Events.IV_START_VERIFICATION_SUCCESS)

      openPlaid(response.data.verification_url)
    }
  }

  useQueryError(startVerificationError, {
    onAllErrors() {
      analytics.logEvent(Analytics.Events.IV_START_VERIFICATION_FAILURE)

      showErrorMessage(errorContent.TITLE, errorContent.SUBTITLE)
    },
  })

  const handleClickUploadPaystubs = () => {
    analytics.logEvent(Analytics.Events.INCOME_VERIFICATION_UPLOAD_PAYSTUBS_CLICKED)

    navigate(Routes.Onboarding.UPLOAD_PAYSTUBS)
  }

  return (
    <VerifyYourIncome
      analyticsScreenName={Analytics.Screens.INCOME_VERIFICATION_BANK_LINK}
      annualIncomeDollars={Number(incomeAmount)}
      onClickContinue={handleOnClickContinue}
      isLoading={isLoading}
      onClickUploadPaystubs={handleClickUploadPaystubs}
    />
  )
}
